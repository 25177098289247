'use client';

import Link from 'next/link';
import styles from './hero.module.css';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { usePathname } from 'next/navigation';
import { MouseEvent } from 'react';
import { useSession } from 'next-auth/react';
function HeroCta() {
	const { navigateTo, isAnimating } = useTransitionContext();
	const currentPath = usePathname();
	const { data: session } = useSession();
	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}
	return (
		<>
			{session?.user ? (
				<Link className={styles.heroCta} href={'/micarta'} aria-label="Crear tu carta digital">
					Ir a mi carta
				</Link>
			) : (
				<Link
					className={styles.heroCta}
					href={'/contacto'}
					onClick={(e) => handleClick(e, '/contacto')}
					aria-label="Crear tu carta digital">
					Crea tu carta
				</Link>
			)}
		</>
	);
}

export default HeroCta;
