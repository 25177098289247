import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import styles from './cardSucess.module.css';
import { Button } from '@/components/ui/button';
import Link from 'next/link';

function CardSucess() {
	return (
		<Card className={styles.card}>
			<CardHeader>
				<CardTitle>Tu cuenta fue creada correctamente</CardTitle>
			</CardHeader>
			<CardContent>
				{/* 
						asChild lo que hace es que el componente Button se renderiza como un hijo de Link 
						*/}
				<Button asChild>
					<Link href={'/iniciar-sesion'}>Inicia sesión</Link>
				</Button>
			</CardContent>
		</Card>
	);
}

export default CardSucess;
