// Form.tsx
'use client';

import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from 'sonner';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styles from './form.module.css';
import { contactFormSchema } from '@/utils/validation/(forms)/contactForm';
import { sendContactForm } from './actions';

// Inferir el tipo de datos del formulario a partir del esquema de Zod

const Form: React.FC = () => {
	// Inicializa useForm con TypeScript y Zod
	const form = useForm<z.infer<typeof contactFormSchema>>({
		resolver: zodResolver(contactFormSchema),
		defaultValues: {
			nombre: '',
			email: '',
			telefono: '',
			nombreLocal: '',
			address: '',
			mensaje: ''
		},
		mode: 'onTouched' // Valida los campos cuando se tocan
	});

	// Función que se ejecuta al enviar el formulario
	async function handleSubmit(data: z.infer<typeof contactFormSchema>) {
		try {
			// Simulamos una llamada a la API con un retraso
			const response = await sendContactForm({
				nombre: data.nombre,
				email: data.email,
				telefono: data.telefono,
				nombreLocal: data.nombreLocal,
				address: data.address,
				mensaje: data.mensaje
			});
			if (response?.error) {
				toast.error(response.message);
			}
			toast.success('Formulario enviado exitosamente!');
			form.reset(); // Reinicia el formulario después de un envío exitoso
		} catch (error: any) {
			// Manejo de errores genéricos
			toast.error('Ocurrió un error al enviar el formulario.');
			console.error(error);
		}
	}

	// Manejo de errores con Sonner
	useEffect(() => {
		if (Object.keys(form.formState.errors).length > 0) {
			// Recorre los errores y muestra un toast por cada uno
			Object.values(form.formState.errors).forEach((error) => {
				if (error?.message) {
					toast.error(error.message);
				}
			});
		}
	}, [form.formState.errors]);

	return (
		<div className={styles.formContentWrapper}>
			<div className={styles.formCard}>
				<form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
					<fieldset disabled={form.formState.isSubmitting}>
						<div className={styles.formFirstInputs}>
							{/* Campo Nombre Completo */}
							<input
								id="nombre"
								{...form.register('nombre')}
								placeholder="Nombre completo"
								maxLength={80}
								aria-invalid={form.formState.errors.nombre ? 'true' : 'false'}
							/>

							{/* Campo Correo Electrónico */}
							<input
								id="email"
								{...form.register('email')}
								type="email"
								placeholder="Correo electrónico"
								maxLength={256}
								aria-invalid={form.formState.errors.email ? 'true' : 'false'}
							/>

							{/* Campo Número de Teléfono con Código de Área */}
							<Controller
								name="telefono"
								control={form.control}
								render={({ field }) => (
									<PhoneInput
										{...field}
										placeholder="e.g., +54 (1234) 5678 9012"
										defaultCountry="AR" // Cambia según tus necesidades
										international
										countryCallingCodeEditable={false} // Evita que el usuario edite el código de país
										className="w-full rounded-md p-2 focus:outline-none"
									/>
								)}
							/>

							{/* Campo Nombre del Local */}
							<input
								id="nombreLocal"
								{...form.register('nombreLocal')}
								placeholder="Nombre del local"
								maxLength={80}
								aria-invalid={form.formState.errors.nombreLocal ? 'true' : 'false'}
							/>
						</div>

						{/* Campo Dirección */}
						<input
							id="address"
							{...form.register('address')}
							placeholder="Dirección"
							maxLength={256}
							aria-invalid={form.formState.errors.address ? 'true' : 'false'}
						/>

						{/* Campo Mensaje */}
						<textarea
							id="mensaje"
							{...form.register('mensaje')}
							placeholder="Detalles adicionales (redes sociales, idiomas, etc)"
							maxLength={500}
							aria-invalid={form.formState.errors.mensaje ? 'true' : 'false'}
						/>

						{/* Botón de Envío */}
						<button type="submit" disabled={form.formState.isSubmitting}>
							{form.formState.isSubmitting ? 'Enviando...' : 'Enviar mensaje'}
						</button>
					</fieldset>
				</form>
			</div>
		</div>
	);
};

export default Form;
