'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { CardContent, Card, CardFooter } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
// import { registerUser } from './actions';
import styles from './registerForm.module.css';
import { toast } from 'sonner';
import { CardSucess } from './cardSucess';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { MouseEvent } from 'react';
import { ComboBox } from './ComboBox';
import { createUserWithCarta } from '@/actions/createUserWithCarta';
import { createUserSchema } from '@/utils/validation/userFormRegister';

function RegisterForm() {
	const currentPath = usePathname();
	const { navigateTo, isAnimating } = useTransitionContext();

	const form = useForm<z.infer<typeof createUserSchema>>({
		resolver: zodResolver(createUserSchema),
		defaultValues: {
			email: '',
			password: '',
			passwordConfirm: '',
			nombreCarta: '',
			nombreLocal: '',
			plantilla: 'restaurante',
			instagram: '',
			facebook: '',
			twitter: '',
			tiktok: '',
			whatsapp: '',
			telefono: ''
		}
	});
	/**
	 * Maneja el envío del formulario.
	 * Valida los datos, llama a la acción de creación y maneja respuestas y errores.
	 * @param data - Datos del formulario validados.
	 */
	async function handleSubmit(data: z.infer<typeof createUserSchema>) {
		const response = await createUserWithCarta({
			email: data.email,
			password: data.password,
			passwordConfirm: data.passwordConfirm,
			nombreCarta: data.nombreCarta,
			nombreLocal: data.nombreLocal,
			plantilla: data.plantilla,
			instagram: data.instagram,
			facebook: data.facebook,
			twitter: data.twitter,
			tiktok: data.tiktok,
			whatsapp: data.whatsapp,
			telefono: data.telefono
		});

		if (!response.success) {
			console.log(data);
			console.log(response?.message);
			form.setError('root', { type: 'manual', message: response?.message });
			toast.error(response?.message);
		} else {
			toast.success(response?.message);
		}
	}
	/**
	 * Maneja el clic en enlaces para navegación con animación.
	 * @param e - Evento del clic.
	 * @param href - Ruta a la que navegar.
	 */
	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}

	return (
		<>
			{/**
			 *	- Si no hay errores en handleSubmit() entonces isSubmitSuccessful será true y se mostrará el mensaje de éxito
			 *  - Esto es el fuerte de react-hook-form porque maneja el estado de los formularios por nosotros
			 */}
			{form.formState.isSubmitSuccessful ? (
				<CardSucess />
			) : (
				<Card className={styles.formCard}>
					<CardContent className={styles.formContent}>
						<Form {...form}>
							<form onSubmit={form.handleSubmit(handleSubmit)} className={styles.form}>
								<fieldset disabled={form.formState.isSubmitting}>
									{/* 
					- Control es para que tengamos intellisense en name=''
					- Usamos typescript y type safety
					- Cada vez que el valor se actualiza automáticamente 
					- Renderizar un mensaje de error automáticamente si hay un mensaje de error para mostrar
					- No necesitamos usar onChange, onBlur, value, etc.
					- Podemos optar por no especificar type="email" ya que Zod maneja la validación por nosotros
					*/}
									<FormField
										control={form.control}
										name="email"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Correo</FormLabel>
												<FormControl>
													<Input {...field} placeholder="ejemplo@gmail.com" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="password"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Contraseña</FormLabel>
												<FormControl>
													<Input {...field} type="password" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="passwordConfirm"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Confirmar contraseña</FormLabel>
												<FormControl>
													<Input {...field} type="password" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="nombreCarta"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Nombre de carta</FormLabel>
												<FormControl>
													<Input {...field} placeholder="Nombre sin espacios" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="nombreLocal"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Nombre del local</FormLabel>
												<FormControl>
													<Input {...field} placeholder="La Carta Resto" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<Controller
										control={form.control}
										name="plantilla"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Tipo de local</FormLabel>
												<FormControl>
													<ComboBox value={field.value} onChange={field.onChange} />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>

									{/* WhatsApp */}
									<FormField
										control={form.control}
										name="whatsapp"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Número de WhatsApp</FormLabel>
												<FormControl>
													<Input {...field} placeholder="+1234567890" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>

									{/* Teléfono */}
									<FormField
										control={form.control}
										name="telefono"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Número de Teléfono</FormLabel>
												<FormControl>
													<Input {...field} placeholder="+1234567890" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>

									{/* Redes Sociales */}
									<FormField
										control={form.control}
										name="instagram"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Instagram</FormLabel>
												<FormControl>
													<Input {...field} placeholder="usuario_de_instagram" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="facebook"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Facebook</FormLabel>
												<FormControl>
													<Input {...field} placeholder="usuario_de_facebook" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="twitter"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Twitter</FormLabel>
												<FormControl>
													<Input {...field} placeholder="usuario_de_twitter" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="tiktok"
										render={({ field }) => (
											<FormItem>
												<FormLabel>TikTok</FormLabel>
												<FormControl>
													<Input {...field} placeholder="usuario_de_tiktok" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<Button className={styles.btn} type="submit">
										{form.formState.isSubmitting ? 'Cargando...' : 'Registrarse'}
									</Button>
								</fieldset>
							</form>
						</Form>
					</CardContent>
					<CardFooter className={styles.formFooter}>
						<div>
							<p>
								¿Ya tienes una cuenta?{' '}
								<Link className="underline" href={'/iniciar-sesion'} onClick={(e) => handleClick(e, '/iniciar-sesion')}>
									Inicia sesión
								</Link>
							</p>
						</div>
					</CardFooter>
				</Card>
			)}
		</>
	);
}

export default RegisterForm;
