'use client';

import styles from './contactOptions.module.css';
import { Mail } from 'lucide-react';
import { toast } from 'sonner';

const textToCopy = 'lacarta.com.ar@gmail.com';
function Email() {
	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy);
			toast.success('Correo copiado en el portapapeles');
		} catch (err) {
			console.error('Failed to copy to clipboard:', err);
		}
	};
	return (
		<button className={styles.option} onClick={copyToClipboard} aria-label="Copiar correo en la papelera">
			<div className={styles.icon}>
				<Mail size={42} />
			</div>
			<div className={styles.info}>
				<p>Envíanos un correo</p>
				<span>lacarta.com.ar@gmail.com</span>
			</div>
		</button>
	);
}

export default Email;
