'use client';

import Link from 'next/link';
import Image from 'next/image';
import styles from './hero.module.css';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { usePathname } from 'next/navigation';
import { MouseEvent } from 'react';

function HeroUsers() {
	const { navigateTo, isAnimating } = useTransitionContext();
	const currentPath = usePathname();
	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}
	return (
		<Link
			className={styles.heroUsersLink}
			href={'/clientes'}
			aria-label="Ver más clientes que usan la aplicación"
			onClick={(e) => handleClick(e, '/clientes')}>
			<div className={styles.heroUsersLinkImageContainer}>
				<Image
					className={styles.heroUsersLinkImage}
					src={'/vectores/flecha.svg'}
					alt="Flecha apuntando a la derecha"
					priority
					fetchPriority="high"
					fill
				/>
			</div>
		</Link>
	);
}

export default HeroUsers;
