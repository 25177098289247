// src/utils/validation/contactForm.ts

import { z } from 'zod';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const contactFormSchema = z.object({
	nombre: z.string().min(1, { message: 'El nombre es obligatorio' }).max(80, { message: 'Máximo 80 caracteres' }),
	email: z.string().email({ message: 'Correo electrónico inválido' }).max(256, { message: 'Máximo 256 caracteres' }),
	telefono: z.string().refine((val) => isValidPhoneNumber(val), { message: 'El teléfono es inválido' }),
	nombreLocal: z
		.string()
		.min(1, { message: 'El nombre del local es obligatorio' })
		.max(80, { message: 'Máximo 80 caracteres' }),
	address: z.string().min(1, { message: 'La dirección es obligatoria' }).max(256, { message: 'Máximo 256 caracteres' }),
	mensaje: z.string().max(500, { message: 'Máximo 500 caracteres' })
});
