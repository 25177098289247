// src/utils/formating/nombreCarta.ts

/**
 * Formatea el nombre de la carta:
 * - Elimina espacios al inicio y al final.
 * - Reemplaza múltiples espacios y guiones por uno solo.
 * - Elimina acentos.
 * - Capitaliza la primera letra de cada palabra y une con guiones.
 * @param nombre - Nombre de la carta en formato libre.
 * @returns Nombre de la carta formateado.
 */
export default function formatNombreCarta(nombre: string): string {
	// console.log(`formatNombreCarta - Input: "${nombre}"`); // Log del input original

	// Trim para eliminar espacios en los extremos
	nombre = nombre.trim();
	// console.log(`formatNombreCarta - Después de trim: "${nombre}"`); // Log después de trim

	// Reemplazar múltiples espacios y guiones por un solo espacio
	nombre = nombre.replace(/[\s\-]+/g, ' ');
	// console.log(`formatNombreCarta - Después de reemplazar espacios y guiones: "${nombre}"`); // Log después de reemplazar espacios y guiones

	// Eliminar acentos utilizando normalize y una expresión regular
	nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	// console.log(`formatNombreCarta - Después de eliminar acentos: "${nombre}"`); // Log después de eliminar acentos

	// Separar por espacios
	const palabras = nombre.split(' ');
	// console.log(`formatNombreCarta - Palabras separadas: ${JSON.stringify(palabras)}`); // Log de palabras separadas

	// Capitalizar y unir con guiones medios
	const nombreFormateado = palabras
		.map((palabra) => {
			if (palabra.length === 0) return ''; // Manejar palabras vacías
			const capitalizado = palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
			console.log(`formatNombreCarta - Palabra original: "${palabra}" → Capitalizada: "${capitalizado}"`); // Log de cada palabra transformada
			return capitalizado;
		})
		.filter((palabra) => palabra.length > 0) // Filtrar palabras vacías después de map
		.join('-');
	// console.log(`formatNombreCarta - Formato final de nombreCarta: "${nombreFormateado}"`); // Log final

	return nombreFormateado;
}
