'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

interface ComboBoxProps {
	value: string;
	onChange: (value: string) => void;
}

const frameworks = [
	{
		value: 'bar',
		label: 'Bar'
	},
	{
		value: 'restaurante',
		label: 'Restaurante'
	}
];

export function ComboBox({ value, onChange }: ComboBoxProps) {
	const [tipoLocalOpen, setTipoLocalOpen] = React.useState(false);

	return (
		<Popover open={tipoLocalOpen} onOpenChange={setTipoLocalOpen}>
			<PopoverTrigger asChild>
				<Button
					role="combobox"
					aria-expanded={tipoLocalOpen}
					className="flex items-center justify-between py-[12px] px-[12px]  w-full bg-white-100 rounded-[12px] text-[15px] hover:bg-white-118 focus:bg-white-118 ssm:text-[14px] msm:text-[16px] md:px-[16px] md:py-[18px] lg:text-[18px] transition-all">
					{value ? frameworks.find((framework) => framework.value === value)?.label : 'Selecciona un tipo...'}
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[200px] p-0">
				<Command>
					<CommandInput placeholder="Buscar tipo..." />
					<CommandList>
						<CommandEmpty>Sin resultados.</CommandEmpty>
						<CommandGroup>
							{frameworks.map((framework) => (
								<CommandItem
									key={framework.value}
									value={framework.value}
									onSelect={(currentValue) => {
										onChange(currentValue === value ? '' : currentValue);
										setTipoLocalOpen(false);
									}}>
									<Check className={cn('mr-2 h-4 w-4', value === framework.value ? 'opacity-100' : 'opacity-0')} />
									{framework.label}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
