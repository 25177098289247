'use client';

import { PencilLine } from 'lucide-react';
import styles from './contactOptions.module.css';

function CompleteForm() {
	function handleClick() {
		const inputElement = document.querySelector('fieldset > div > input') as HTMLInputElement | null; // Asegúrate de que sea un HTMLInputElement

		if (inputElement) {
			// Verifica que el elemento no sea null
			inputElement.focus();
		}
	}

	return (
		<button className={styles.option} onClick={handleClick}>
			<div className={styles.icon}>
				<PencilLine size={42} />
			</div>
			<div className={styles.info}>
				<p>Rellena el formulario</p>
				<span>Te contactaremos lo más pronto posible</span>
			</div>
		</button>
	);
}

export default CompleteForm;
