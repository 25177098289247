'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { CardContent, Card, CardFooter } from '@/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { loginWithCredentials } from '@/actions/loginWithCredentials';
import styles from './loginForm.module.css';
import { toast } from 'sonner';
import { passwordSchema } from '@/utils/validation/passwordSchema';
import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { MouseEvent } from 'react';

const formSchema = z.object({
	email: z.string().email({ message: 'Correo inválido' }),
	password: passwordSchema
});

function LoginForm() {
	const currentPath = usePathname();
	const { navigateTo, isAnimating } = useTransitionContext();
	const router = useRouter();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: 'test@test.com',
			password: '12345'
		}
	});

	async function handleSubmit(data: z.infer<typeof formSchema>) {
		const response = await loginWithCredentials({
			email: data.email,
			password: data.password
		});
		if (response?.error) {
			form.setError('root', {
				message: response.message
			});
			toast.error(response.message);
		} else {
			router.push('/micarta');
		}
	}

	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}

	return (
		<>
			{/* 
		- Si no hay errores en handleSubmit() entonces isSubmitSuccessful será true y se mostrará el mensaje de éxito
		- Esto es el fuerte de react-hook-form porque maneja el estado de los formularios por nosotros
		*/}
			<Card className={styles.formCard}>
				<CardContent className={styles.formContent}>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(handleSubmit)} className={styles.form}>
							<fieldset disabled={form.formState.isSubmitting}>
								{/* 
					- Control es para que tengamos intellisense en name=''
					- Usamos typescript y type safety
					- Cada vez que el valor se actualiza automáticamente 
					- Renderizar un mensaje de error automáticamente si hay un mensaje de error para mostrar
					- No necesitamos usar onChange, onBlur, value, etc.
					- Podemos optar por no especificar type="email" ya que Zod maneja la validación por nosotros
					*/}
								<FormField
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Correo</FormLabel>
											<FormControl>
												<Input {...field} placeholder="ejemplo@gmail.com" />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="password"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Contraseña</FormLabel>
											<FormControl>
												<Input {...field} type="password" />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								{!!form.formState.errors.root?.message && (
									<FormMessage className="-translate-y-[12px] h-0">{form.formState.errors.root.message}</FormMessage>
								)}
								<Button type="submit">{form.formState.isSubmitting ? 'Cargando...' : 'Iniciar sesión'}</Button>
							</fieldset>
						</form>
					</Form>
				</CardContent>
				<CardFooter className={styles.formFooter}>
					<div>
						<p>
							¿No tienes una cuenta?{' '}
							<Link className="underline" href={'/contacto'} onClick={(e) => handleClick(e, '/contacto')}>
								Contáctanos
							</Link>
						</p>
					</div>
					{/* <div>
						<p>
							¿Olvidaste tu contraseña?{' '}
							<Link
								className="underline"
								href={'/recuperar-contrasena'}
								onClick={(e) => handleClick(e, '/recuperar-contraseña')}>
								Recuperar contraseña
							</Link>
						</p>
					</div> */}
				</CardFooter>
			</Card>
		</>
	);
}

export default LoginForm;
