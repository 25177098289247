// validators/userValidator.ts

import { z } from 'zod';
import formatNombreCarta from '@/utils/formating/nombreCarta'; // Asegúrate de que la ruta es correcta
import { passwordMatchSchema } from './passwordMatchSchema';

/**
 * Esquema de validación para la creación de un usuario y su carta.
 * Utiliza 'zod' para definir las reglas de validación y transformación.
 */
export const createUserSchema = z
	.object({
		email: z
			.string()
			.email({ message: 'Correo inválido' }) // Valida que el string sea un email válido
			.transform((str) => str.trim().toLowerCase()), // Transforma a minúsculas y elimina espacios
		nombreCarta: z
			.string()
			.min(1, { message: 'Nombre de carta inválido' }) // Debe tener al menos un carácter
			.max(50, { message: 'Nombre de carta demasiado largo' }) // Máximo 50 caracteres
			.transform((str) => formatNombreCarta(str)), // Formatea el nombre de la carta
		nombreLocal: z
			.string()
			.min(1, { message: 'Nombre del local inválido' }) // Nombre del local con al menos un carácter
			.max(50, { message: 'Nombre del local demasiado largo' }), // Máximo 50 caracteres
		plantilla: z.enum(['bar', 'restaurante'], { message: 'Debe seleccionar una plantilla' }), // Debe ser 'bar' o 'restaurante'
		instagram: z.string().optional(), // Redes sociales son opcionales
		facebook: z.string().optional(),
		twitter: z.string().optional(),
		tiktok: z.string().optional(),
		whatsapp: z.string().optional(),
		telefono: z.string().optional()
	})
	.and(passwordMatchSchema);

/**
 * Tipo inferido a partir del esquema de validación.
 * Representa la estructura de los datos validados.
 */
export type CreateUserInput = z.infer<typeof createUserSchema>;
