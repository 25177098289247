'use client';

import Link from 'next/link';
import styles from './hero.module.css';
import { useTransitionContext } from '@/contexts/TransitionContext';
import { usePathname } from 'next/navigation';
import { MouseEvent } from 'react';
import { signOut, useSession } from 'next-auth/react';

function HeroLogin() {
	const { navigateTo, isAnimating } = useTransitionContext();
	const currentPath = usePathname();
	const { data: session } = useSession();
	function handleClick(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLImageElement>, href: string) {
		if (currentPath !== href && !isAnimating) {
			e.preventDefault();
			navigateTo(href);
		} else {
			e.preventDefault(); // Prevenir navegación si está animando o en la misma ruta
		}
	}
	return (
		<>
			{session?.user ? (
				<button className={styles.heroLogin} onClick={() => signOut()}>
					Cerrar sesión
				</button>
			) : (
				<Link
					className={styles.heroLogin}
					href={'/iniciar-sesion'}
					onClick={(e) => handleClick(e, '/iniciar-sesion')}
					aria-label="Iniciar sesión en tu cuenta">
					Iniciar sesión
				</Link>
			)}
		</>
	);
}

export default HeroLogin;
